.DateInput_input {
  display: block !important;
  width: 100% !important;
  padding: 0.375rem 0.75rem !important;
  font-size: 0.9375rem !important;
  line-height: 1.6 !important;
  color: #495057 !important;
  background-color: #fff !important;
  background-clip: padding-box !important;
  border: 1px solid rgb(191, 191, 191) !important;
  border-radius: 4px !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}
.DateInput_input:hover {
  border: 1px solid rgb(29, 37, 43) !important;
  box-shadow: 0 0 0 3px #000 inset; 
}
.DateInput_input:focus {
  border: 2px solid rgb(48, 59, 166) !important;
}
.GroupInformPicker .DateInput_input:hover {
  border: 1px solid rgb(29, 37, 43) !important;
  box-shadow: 0 0 0 1px #000 inset; 
}
.GroupInformPicker .DateInput_input:focus {
  border: 1px solid rgb(48, 59, 166) !important;
}
.GroupInformPicker .DateRangePicker {
  /* width: 100% !important; */
  margin-top: 16px !important;
}
.GroupInformPicker .DateRangePicker_picker {
  width: 100% !important;
}
.GroupInformPicker .DayPicker {
  width: 100% !important;
}
.GroupInformPicker .DayPicker>div>div {
  margin: auto !important;
}

.SingleDatePickerInput>.DateInput, .SingleDatePicker, .DateRangePicker {
  width: 100% !important;
}
.DateRangePickerInput>.DateInput {
  width: 40% !important;
}
.DateRangePickerInput_arrow {
  width: 20% !important;
  text-align: center;
}
.DateRangePickerInput {
  width: 100% !important;
  border: 0px !important;
  background-color: inherit !important;
}

.SingleDatePickerInput__withBorder {
  border-radius: inherit !important;
  border: inherit !important;
  width: 100% !important;
}
