.MuiCard-root {
  overflow: visible !important;
}
.MuiAppBar-colorPrimary {
  color: #3f51b5 !important;
  background-color: white !important;
  border-bottom: 1px solid #3f51b5;
  z-index: 1300 !important;
}
/* header,.MuiSvgIcon-root {
  color: #3f51b5;
} */

#tableWrapper .MuiTableCell-sizeSmall {
  padding: 6px 12px 6px 10px !important;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type="number"] {
  text-align: center;
}