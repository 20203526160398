@media screen and (max-width: 959px) { 
  .ItineraryItemWrapper .MuiCardHeader-root {
    display: block;
  }
  .ItineraryItemWrapper .MuiCardHeader-action {
    margin-right: 0px;
    margin-bottom: 15px;
  }
  .ItineraryItemWrapper .MuiAutocomplete-root {
    width: 100% !important;
  }
}
