#lsWrapper {
  position: fixed;
  width: 212px;
  height: 52px;
  line-height: 52px;
  top: 0;
  left: 0;
  bottom: 60px;
  right: 0;
  margin: auto;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid #ccc;
  box-shadow: rgba(52,58,64,0.4) 0px 0px 15px 15px;
  background: white;
  border-radius: 0.4rem;
  z-index: 1501;
  color: #dae5f5;
  background-color: #24426c;
  border-color: #3c516f;
}
.lsContent {
    width: 150px;
    height: 52px;
    float: right;
    text-align: center;
    font-size: 21px;
}
.lsSpinner {
  float: left;
  margin: 11px 0 0 19px;
}

.loginPageLoadingSpinner {
  margin: auto !important;
}
.lsWrapperBackground {
  background-color: black;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1500;
  top: 0px;
  opacity: 0.6;
}
.spinner{
  margin: 10px 5px 10px 20px;
  float: left;
}